module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"white","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/images/icons/favicon.png","name":"Korean Tao Fashion Association","short_name":"KRTFA","description":"Korean Tao Fashion Association Website","start_url":"/","background_color":"#5ed7ed","theme_color":"#ffffff","display":"standalone","lang":"ko","localize":[{"start_url":"/kr/","lang":"ko","name":"Korean Tao Fashion Association","short_name":"KRTFA","description":"Korean Tao Fashion Association Website"},{"start_url":"/cn/","lang":"zh","name":"Korean Tao Fashion Association","short_name":"KRTFA","description":"Korean Tao Fashion Association Website"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ee23271baf8dfa39239789345d88763e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/workpath0/src/intl","languages":["ko","en","cn"],"defaultLanguage":"ko","redirect":true,"redirectComponent":"/vercel/workpath0/src/components/redirect.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
